import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes/AppRoute';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Chatbot from './site/chatbot/Chatbot';
import { userStore } from './store/userStore';
import authQueries from './queries/authQueries';


function App() {

  const [chatbot, setChatbot] = useState(false)

  // const getSettings = async () => {
  //   try {
  //     const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/settings.json");
  //     const data = await response.json();
  //     const chatbot = data?.chatbot;
  //     if(chatbot){
  //       setChatbot(true)
  //     }else{
  //       setChatbot(false)
  //     }
  //   }catch(error){
  //     console.error("Error fetching or parsing the settings:", error.message);
  //   }
  // }

  const chatbotStatus = authQueries.useChatbotStatusMutation(
    async (response) => {
      console.log("Chatbot status response", response?.isVisible);
      if(response?.isVisible==='TRUE'){
        setChatbot(true)
    }else{
        setChatbot(false)
    } 
  }
  );

  useEffect(() => {
    chatbotStatus.mutateAsync()
    //getSettings();
  }, []);

  return (
    <div className="App">
        {
          chatbot &&
          <Chatbot />
        }
      <AppRoutes />
    </div>
  );
}

export default App;
